import { services } from './services';
import { OfferedServices } from 'src/__generated__/graphql';
import Button from '@/components/ui/Button';
import { useCallback, useMemo, useState } from 'react';
import Icon from '@/components/ui/icon';
import colors from '@/utils/colors';
import { FragmentType, gql, useFragment as fragment } from 'src/__generated__';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import { getTimezone } from '@/utils/helper';
import { AvailabilityWindowPicker } from '../AvailabilityWindowPicker';
import {
  AvailabilityContent,
  getDefaultAvailabilities,
  useAvailabilityErrors,
} from '../AvailabilityWindowPicker/utils';
import dayjs from 'dayjs';
import { BsPlus } from 'react-icons/bs';
import { formatISO } from 'date-fns';
import isDefined from '@/utils/isDefined';

const BookServiceAppointmentMutation = gql(/* GraphQL */ `
  mutation BookServiceAppointment(
    $input: CreateWaitListSignupForCustomerInput!
  ) {
    bookServiceJob(input: $input) {
      message
      success
      customer {
        rawId
        id
        waitListServices {
          isOnWaitList
          service
        }
      }
    }
  }
`);

const CustomerLocationFragment = gql(/* GraphQL */ `
  fragment CustomerLocation on Customer {
    locations {
      id
      rawId
      street
      apt
      city
      state
      zipcode {
        zipcode
      }
    }
  }
`);

type BookServiceProps = {
  service: OfferedServices;
  clearService: () => void;
  customer: FragmentType<typeof CustomerLocationFragment>;
  setBooking: (_sucess: boolean) => void;
  setBooked: (_sucess: boolean) => void;
};

export function BookService({
  service,
  clearService,
  customer: customerFragment,
  setBooked,
  setBooking,
}: BookServiceProps) {
  const customer = fragment(CustomerLocationFragment, customerFragment);

  const location = customer.locations?.[0];

  const defaultAvailabilityWindows = getDefaultAvailabilities({
    numAvailabilitiesToGenerate: 1,
  });
  const [availabilityWindows, setAvailabilityWindows] = useState<
    Array<AvailabilityContent>
  >(defaultAvailabilityWindows);

  const minAvailabilityDate = useMemo(() => {
    const timezone = getTimezone();
    return dayjs().tz(timezone).startOf('day').toDate();
  }, []);
  const maxAvailabilityDate = useMemo(() => {
    const timezone = getTimezone();
    return dayjs().tz(timezone).startOf('day').add(2, 'M').toDate();
  }, []);

  const updateWindow = (index: number) => (window: AvailabilityContent) => {
    const newWindows = availabilityWindows.map((w, i) =>
      i === index ? window : w,
    );
    setAvailabilityWindows(newWindows);
  };

  const onAddAvailability = useCallback(() => {
    const availabilitiesToAdd = getDefaultAvailabilities({
      numAvailabilitiesToGenerate: 1,
      takenAvailabilities: availabilityWindows,
    });
    setAvailabilityWindows([
      ...(availabilityWindows || []),
      availabilitiesToAdd[0],
    ]);
  }, [availabilityWindows]);

  const [bookService] = useMutation(BookServiceAppointmentMutation);

  const [loading, setLoading] = useState(false);

  const errors = useAvailabilityErrors(availabilityWindows);

  const isValid = useMemo(
    () =>
      !errors?.filter(isDefined).length &&
      availabilityWindows.every((x) => x.endTime && x.startTime),
    [availabilityWindows, errors],
  );

  const onSubmit = useCallback(async () => {
    try {
      setBooking(true);
      setLoading(true);
      const result = await Promise.all([
        // This loading state needs to feel like it is actually trying
        new Promise((r) => setTimeout(r, 3000)),
        bookService({
          variables: {
            input: {
              service,
              windows: availabilityWindows
                .filter((x) => x.endTime && x.startTime && x.startDate)
                .map((window) => ({
                  start: formatISO(
                    window.startDate!.setHours(
                      Number(window.startTime!.split(':')[0]),
                      Number(window.startTime!.split(':')[1]),
                    ),
                  ),
                  end: formatISO(
                    window.startDate!.setHours(
                      Number(window.endTime!.split(':')[0]),
                      Number(window.endTime!.split(':')[1]),
                    ),
                  ),
                })),
            },
          },
          refetchQueries: ['GetWaitlistForCustomer'],
        }),
      ])
        .then(([_, result]) => result)
        .finally(() => {
          setBooking(false);
          setLoading(false);
        });

      if (result.data?.bookServiceJob?.success) {
        setBooked(true);
        return;
      }

      const errorMsg =
        result.data?.bookServiceJob?.message ||
        'An unexpected error occurred when booking this service. Please try again.';

      toast.error(errorMsg);
    } catch (e) {
      toast.error(
        'An unexpected error occurred when booking this service. Please try again.',
      );
    }
  }, [setBooking, bookService, service, availabilityWindows, setBooked]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
      className="overflow-y-scroll flex-grow flex items-start justify-between flex-col w-full overflow-hidden relative bg-white px-4 lg:px-6 py-8 lg:pt-6 pb-0 "
    >
      <div className="flex flex-col  items-start w-full">
        <div className="flex flex-col items-start mb-6">
          <p className="text-xl font-bold">Book a service</p>
        </div>
        <div className="w-full mb-6">
          <div className="flex flex-row justify-between text-left w-full">
            <div className="flex flex-row justify-start">
              <div className="flex-shrink-0 rounded-lg overflow-clip self-center mr-3">
                <Icon
                  name={services[service].icon}
                  size={60}
                  color={colors.brand.purple}
                />
              </div>
              <div className="self-center flex flex-col">
                <p className="text-sm font-medium">{services[service].name}</p>
              </div>
            </div>
            <div className="ml-auto self-center">
              <Button
                variant="tertiary"
                size="sm"
                onClick={() => clearService()}
                className="text-sm font-normal bg-lightestGray"
              >
                Change
              </Button>
            </div>
          </div>
        </div>
        <div className="w-full mb-6">
          <p className="text-md font-medium mb-2">Arrival dates and times</p>
          <p className="text-md font-normal">
            Let us know when you would be able to welcome your pro. Add more
            options to improve the chances that they will be available.
          </p>
        </div>
        <div className="w-full mb-6">
          {availabilityWindows.map((item, idx) => {
            return (
              <div className="flex-1 mb-4" key={idx}>
                <AvailabilityWindowPicker
                  label="Date of service"
                  minAvailabilityDate={minAvailabilityDate}
                  maxAvailabilityDate={maxAvailabilityDate}
                  availabilityWindow={item}
                  onWindowChange={updateWindow(idx)}
                  errorMessage={errors?.[idx]}
                />
              </div>
            );
          })}
          {availabilityWindows?.length && availabilityWindows.length < 3 ? (
            <Button
              variant={'tertiary'}
              className="bg-lightestGray max-w-full mt-4 font-medium"
              onClick={(e) => {
                e.preventDefault();
                onAddAvailability();
              }}
              leftIcon={<BsPlus />}
            >
              Add another time
            </Button>
          ) : null}
        </div>
      </div>
      {location?.street &&
        location.city &&
        location.state &&
        location.zipcode?.zipcode && (
          <div className="w-full mb-6">
            <p className="text-md font-medium mb-2">Address</p>
            <p className="text-md font-normal">{location.street}</p>
            <p className="text-md font-normal">
              {location.city}, {location.state} {location.zipcode.zipcode}
            </p>
          </div>
        )}
      <div className="w-full">
        <Button
          loading={loading}
          disabled={loading || !isValid}
          variant="primary"
          className="w-full mb-6"
        >
          Find pros in your area
        </Button>
      </div>
    </form>
  );
}
