import { WAITLIST_AD_BANNER_DISMISSED } from '@/utils/constants';
import { useState, useCallback, useEffect } from 'react';
import Mixpanel from '@/utils/mixpanel';
import Icon from '@/components/ui/icon';
import colors from '@/utils/colors';
import { BsX } from 'react-icons/bs';
import { WaitListAd } from '../Waitlist/WaitListAd';

type WaitlistAdBannerProps = {
  experiments: Record<string, string | undefined | null>;
  loading: boolean;
};

export function WaitlistAdBanner({
  experiments,
  loading,
}: WaitlistAdBannerProps) {
  const [showWaitlistAdBanner, setShowWaitlistAdBanner] = useState(false);
  const [isOpen, toggleModal] = useState(false);

  const dismissWaitlistAdBanner = useCallback((e: any) => {
    e?.preventDefault?.();
    if (typeof localStorage === 'undefined') return;
    localStorage.setItem(WAITLIST_AD_BANNER_DISMISSED, 'true');
    setShowWaitlistAdBanner(false);
  }, []);

  useEffect(() => {
    if (
      typeof localStorage === 'undefined' ||
      !experiments['customer_services_waitlist_enabled'] ||
      experiments['customer_services_waitlist_enabled'] !== 'yes'
    )
      return;
    // if they haven't dismissed the banner
    if (!localStorage.getItem(WAITLIST_AD_BANNER_DISMISSED)) {
      Mixpanel.track('waitlist_ad_banner_shown');
      setShowWaitlistAdBanner(true);
    }
  }, [experiments]);

  const showWaitlist =
    showWaitlistAdBanner &&
    experiments['customer_services_waitlist_enabled'] === 'yes';

  if (!showWaitlist || loading) return null;

  return (
    <div className="w-full sm:px-1 md:px-2 lg:px-0">
      <button
        type="button"
        className={
          'flex flex-row w-full items-center justify-between text-left mb-2 px-4 rounded-lg bg-green'
        }
        onClick={() => toggleModal(true)}
      >
        <div className="flex flex-row items-center my-4">
          <div className="w-5 h-5 flex-shrink-0">
            <Icon name="house" size={24} color={colors.brand.black} />
          </div>
          <div className="ml-4 text-md">
            <p className="font-bold">New! Find pros for home services</p>
            <p className="font-normal">
              Book a dog walker, home organizer, car washer and more.
            </p>
          </div>
        </div>
        <button
          onClick={dismissWaitlistAdBanner}
          className="h-11 w-11 flex justify-center items-center"
          aria-label="Close home services banner"
        >
          <BsX className="h-5 w-5" />
        </button>
      </button>
      <WaitListAd isOpen={isOpen} close={() => toggleModal(false)} />
    </div>
  );
}
