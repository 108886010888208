import React, { PropsWithChildren, useEffect } from 'react';
import {
  formatDate,
  humanizeDateTime,
  humanizeMonthAndDate,
} from '@/utils/helper';
import Link from 'next/link';
import {
  LegacyCustomerAlert,
  LegacyCustomerJobAlert,
} from 'src/__generated__/graphql';
import * as Sentry from '@sentry/nextjs';
import { formatWithDecimal } from '@/utils/formatCurrency';
import Mixpanel from '@/utils/mixpanel';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import { baseUrl } from 'src/hooks/useBrandData';
import useNewBookAgainExperimentCohort from '@/hooks/useNewBookAgainExperimentCohort';

export interface AlertProps {
  data:
    | LegacyCustomerAlert
    | LegacyCustomerJobAlert
    | { __typename: 'NoCardAlert' }
    | { __typename: 'ProfileAttributesAlert' };
  cleanerFirstName?: string | null;
  jobId?: string | null;
}
const _Alert = ({ data, cleanerFirstName, jobId }: AlertProps) => {
  useEffect(() => {
    Mixpanel.track(`AlertShown`, { typename: data.__typename, jobId });
  }, [data.__typename, jobId]);
  const { hasNewBookAgain } = useNewBookAgainExperimentCohort();

  switch (data.__typename) {
    case 'FailedChargesAlert':
      return (
        <AlertContainer className="bg-orange">
          <p>
            We weren&apos;t able to process your card on file, which may affect
            your cleaner&apos;s pay. Please
            <Link
              href="/settings/credit_card"
              className="text-darkPurple ml-1 underline"
            >
              update your card
            </Link>
            .
          </p>
        </AlertContainer>
      );
    case 'SystemCancelJobAlert':
      return data.starts?.length ? (
        <AlertContainer className="bg-orange">
          <p>
            We&apos;re sorry, but no cleaner accepted your request for{' '}
            {data.starts.map((date, i, starts) => {
              const nextTolastDateIndex = starts.length - 2;
              const monthAndDate = humanizeMonthAndDate(date);

              if (i < nextTolastDateIndex) {
                return monthAndDate + ', ';
              }

              if (i === nextTolastDateIndex) {
                return monthAndDate + ' or ';
              }

              return monthAndDate;
            })}
            . You were not charged. To submit a priority request,
            <Link
              href={hasNewBookAgain ? '/book' : '/new'}
              className="text-darkPurple ml-1 underline"
            >
              click here
            </Link>
            .
          </p>
        </AlertContainer>
      ) : null;
    case 'FirstJobAlert':
      return (
        <AlertContainer className="bg-green">
          <ul className="list-disc list-outside ml-4">
            <li>
              <p>
                {humanizeDateTime(data.start || new Date())} request sent to
                your preferred cleaners. Your cleaner will reach out the morning
                of your appointment.
              </p>
            </li>
            <li>
              <p>
                Prioritize what&apos;s important using
                <Link
                  className="text-darkPurple ml-1 underline"
                  href="/settings/cleaning_notes"
                >
                  your notes
                </Link>
                . Your cleaner will bring standard supplies.
              </p>
            </li>
            <li>
              <p>
                If you&apos;ll be home, give your cleaner a tour so they can
                plan out their time.
              </p>
            </li>
            <li>
              <p>
                After your cleaning, your dashboard will let you leave a review
                & optional tip for your cleaner.
              </p>
            </li>
          </ul>
        </AlertContainer>
      );
    case 'CPRescheduledNotRequestedAlert':
      return (
        <AlertContainer className="bg-orange">
          <p>
            {cleanerFirstName || 'Your cleaner'} rescheduled your appointment to{' '}
            {formatDate(new Date(data.date || ''))}. If you need to reschedule,
            <Link
              className="text-darkPurple ml-1 underline"
              href={`/appointment/${jobId}?action=reschedule`}
            >
              click here
            </Link>
            .
          </p>
        </AlertContainer>
      );
    case 'CPSwappedAlert':
      return (
        <AlertContainer className="bg-green">
          <p>
            Your previous cleaner was unavailable so we matched you with{' '}
            {data.cpFirstName}!
          </p>
        </AlertContainer>
      );
    case 'HoursChangedCPNotApprovedAlert':
      return (
        <AlertContainer className="bg-orange">
          <p>
            {cleanerFirstName || 'Your cleaner'} will make every effort to
            accommodate your new appointment duration. Other commitments may
            limit their availability beyond the original{' '}
            {data.lastApprovedNumHours} hours. You&apos;ll only be charged for
            actual hours worked.
          </p>
        </AlertContainer>
      );
    case 'ChargedForLockoutAlert':
      return (
        <AlertContainer className="bg-orange">
          <p>
            You were charged because your cleaner couldn&apos;t enter your home
            on {formatDate(data.date)}, per our
            <Link
              className="text-darkPurple ml-1 underline"
              href={baseUrl + '/help/139'}
            >
              policy
            </Link>
            .
          </p>
        </AlertContainer>
      );
    case 'ChargedForLastMinuteCancelAlert':
      return (
        <AlertContainer className="bg-orange">
          <p>
            You were charged for canceling last-minute on{' '}
            {formatDate(data.date)}, per our
            <Link className="text-darkPurple ml-1 underline" href="/help">
              policy
            </Link>
            .
          </p>
        </AlertContainer>
      );
    case 'CreditsAvailableAlert':
      return (
        <AlertContainer className="bg-green">
          <p>
            Your{' '}
            {formatWithDecimal(
              data.creditsAvailable ? data.creditsAvailable / 100 : 0,
            )}{' '}
            credit will apply to your next appointment.
          </p>
        </AlertContainer>
      );
    case 'ProfileAttributesAlert':
      return (
        <Link href="/settings/profile">
          <div className={'mb-2 rounded-lg overflow-hidden bg-green'}>
            <div className="w-full p-4">
              <p className="font-bold flex items-center">
                You&apos;re almost done!
                <BsBoxArrowUpRight
                  className="inline ml-2"
                  aria-label="links to profile page"
                />
              </p>
              <p className="">
                Take a couple minutes to update your profile. This helps us
                match you with the best cleaner!
              </p>
            </div>
            <div className="w-full h-1">
              <div className="w-2/3 bg-darkGreen h-full rounded-r"></div>
            </div>
          </div>
        </Link>
      );
    case 'NoCardAlert':
      return (
        <div className="bg-orange flex mb-2 p-4 border-darkerRed border-2 rounded-lg font-semibold">
          <p>We require a credit card as a backup for all cleanings </p>
          <AlertButton href="/settings/credit_card">
            Add Credit Card
          </AlertButton>
        </div>
      );
    default:
      return null;
  }
};

const AlertContainer = ({
  className,
  children,
}: PropsWithChildren<{ className?: string }>) => {
  return <div className={'mb-2 p-4 rounded-lg ' + className}>{children}</div>;
};

const AlertButton = ({
  children,
  href,
}: {
  children: string;
  href: string;
}) => {
  return (
    <Link
      className={`
        flex justify-center items-center text-center 
        text-white bg-purple hover:bg-darkPurple 
        ml-2 sm:px-1 md:px-2 rounded font-semibold text-sm
      `}
      href={href}
    >
      {children}
    </Link>
  );
};

export default function Alert(props: AlertProps) {
  return (
    <Sentry.ErrorBoundary fallback={<>{null}</>}>
      <_Alert {...props} />
    </Sentry.ErrorBoundary>
  );
}
