import { useState, useEffect, useCallback } from 'react';
import { theme } from '../../tailwind.config';

const breakpoints = theme?.screens as { [key: string]: string };
const breakpointSizes = Object.entries(breakpoints).map(([name, size]) => [
  name,
  parseInt(size.replace('px', '')),
]);

const useBreakpoint = () => {
  const [size, setSize] = useState(0);
  const [breakpointName, setBreakpointName] = useState<string>('sm');

  const resize = useCallback(() => {
    setSize(window.innerWidth);
    breakpointSizes.forEach(([name, size]) => {
      if (window.innerWidth >= (size as number)) {
        setBreakpointName(name as string);
      }
    });
  }, []);

  useEffect(() => {
    resize();
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [resize]);

  return { size, breakpoint: breakpointName };
};

export default useBreakpoint;
