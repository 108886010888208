import { AnswersData, QuestionType } from '@/components/ui/SurveyQuestion';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

type Section = {
  title?: string;
  questions: QuestionType[];
  showIf?: (_ans: AnswersData) => boolean;
};

/**
 * NOTES ON UPDATING THIS:
 *
 * - When changing the value of a question's option, the old value that is stored
 * in the db will still be the "value" in the form's state unless overridden.
 * For multi-selects, this means that old options will still be selected (and not
 * shown in the UI if the options are updated). For multi-selects with a limit,
 * this may look like "I have 3 options selected and i'm seeing an error that I
 * cant select more then 3". SO...
 *
 * TODO:
 * - if a saved value isn't in the new options, remove it from the form state
 */
export const sections: Section[] = [
  {
    questions: [
      {
        name: 'bedrooms',
        title: 'How many bedrooms do you have?',
        type: 'single-select',
        required: true,
        validate: yup.string().required('This field is required'),
        options: [
          { label: '1', value: '1' },
          { label: '2', value: '2' },
          { label: '3', value: '3' },
          { label: '4', value: '4' },
          { label: '5+', value: '5+' },
        ],
      },
      {
        name: 'bathrooms',
        title: 'How many bathrooms do you have?',
        type: 'single-select',
        required: true,
        validate: yup.string().required('This field is required'),
        options: [
          { label: '1', value: '1' },
          { label: '1.5', value: '1.5' },
          { label: '2', value: '2' },
          { label: '2.5', value: '2.5' },
          { label: '3', value: '3' },
          { label: '3.5', value: '3.5' },
          { label: '4+', value: '4+' },
        ],
      },
      {
        name: 'sqft',
        title: "What's your indoor square footage?",
        type: 'single-select',
        required: true,
        validate: yup.string().required('This field is required'),
        options: [
          { label: '< 500 sqft', value: '<500' },
          { label: '500 - 1,000 sqft', value: '500-1000' },
          { label: '1,001 - 1500 sqft', value: '1001-1500' },
          { label: '1,501 - 2,000 sqft', value: '1501-2000' },
          { label: '2,001 - 2,500 sqft', value: '2001-2500' },
          { label: '2,501 - 3,000 sqft', value: '2501-3000' },
          { label: '3,001 - 4,000 sqft', value: '3001-4000' },
          { label: '4,001 - 5,000 sqft', value: '4001-5000' },
          { label: '5,001+ sqft', value: '5001+' },
        ],
      },
      {
        name: 'building-type',
        title: 'What type of building do you live in?',
        type: 'single-select',
        required: true,
        validate: yup.string().required('This field is required'),
        options: [
          { label: 'Apartment', value: 'apartment' },
          { label: 'Single-family home', value: 'single-family' },
          { label: 'Townhome', value: 'townhome' },
          { label: 'Condo', value: 'condo' },
          { label: 'Manufactured home', value: 'manufactured' },
          { label: 'Commercial space', value: 'commercial' },
          { label: 'Other', value: 'other' },
        ],
      },
    ],
  },
  {
    title: 'About your cleaner',
    questions: [
      // {
      //   name: 'gender-preference',
      //   required: true,
      //   title: 'Do you have a gender preference for your cleaner?',
      //   type: 'single-select',
      //   validate: yup.string().required('This field is required'),
      //   options: [
      //     { label: 'No preference', value: 'no-preference' },
      //     { label: 'Woman', value: 'woman' },
      //     { label: 'Man', value: 'man' },
      //   ],
      // },
      {
        name: 'languages',
        required: true,
        validate: yup
          .array()
          .of(yup.string())
          .required()
          .min(1, 'You must select at least 1 option'),
        title: 'What languages would you feel comfortable with?',
        description: 'Select as many as you like',
        type: 'multi-select',
        options: [
          { label: 'All languages are ok', value: 'all' },
          { label: 'English', value: 'english' },
          { label: 'Spanish', value: 'spanish' },
          { label: 'Portuguese', value: 'portuguese' },
          { label: 'Chinese', value: 'chinese' },
          { label: 'French', value: 'french' },
          { label: 'Russian', value: 'russian' },
        ],
      },
      {
        name: 'last-cleaning',
        required: true,
        validate: yup.string().required('This field is required'),
        title: 'When was the last time you hired a cleaner before us?',
        type: 'single-select',
        options: [
          { label: '< 1 month ago', value: '<1-month' },
          { label: '1-3 months ago', value: '1-3-months' },
          { label: '4-6 months ago', value: '4-6-months' },
          { label: '7+ months ago', value: '>7-months' },
          { label: "I've never hired a cleaner before", value: 'never' },
        ],
      },
    ],
  },
  {
    title: 'About your next cleaning',
    questions: [
      {
        name: 'vacuum-available',
        required: true,
        validate: yup.string().required('This field is required'),
        title: 'Do you have a vacuum that your cleaner can use?',
        type: 'yes-no',
      },
      {
        name: 'equipment-available',
        required: true,
        validate: yup
          .array()
          .required()
          .of(yup.string())
          .min(1, 'You must select at least 1 option'),
        title: 'What other equipment do you have for your cleaner?',
        description: 'Select as many as you like',
        type: 'multi-select',
        options: [
          { label: 'Mop', value: 'mop' },
          { label: 'Bucket', value: 'bucket' },
          { label: 'Broom', value: 'broom' },
          { label: 'Dustbin', value: 'dustbin' },
          { label: 'Toilet Brush', value: 'toilet-brush' },
          { label: 'Duster', value: 'duster' },
          { label: 'Rags', value: 'rags' },
          { label: 'All of the above', value: 'all' },
          { label: 'None of the above', value: 'none' },
          { label: "I'd prefer they bring their own", value: 'bring-own' },
        ],
      },
      {
        name: 'supplies-available',
        required: true,
        validate: yup
          .array()
          .required()
          .of(yup.string())
          .min(1, 'You must select at least 1 option'),
        title: 'What cleaning supplies do you have for your cleaner?',
        description: 'Select as many as you like',
        type: 'multi-select',
        options: [
          { label: 'All-purpose spray', value: 'all-purpose-spray' },
          { label: 'Glass cleaner', value: 'glass-cleaner' },
          { label: 'Toilet cleaner', value: 'toilet-cleaner' },
          { label: 'Floor cleaner', value: 'floor-cleaner' },
          { label: 'Paper towels', value: 'paper-towels' },
          { label: 'All of the above', value: 'all' },
          { label: 'None of the above', value: 'none' },
          { label: "I'd prefer they bring their own", value: 'bring-own' },
        ],
      },
      {
        name: 'someone-home',
        required: true,
        validate: yup.string().required('This field is required'),
        title: 'Will someone be home during the cleanings?',
        type: 'single-select',
        options: [
          { label: 'Always', value: 'always' },
          { label: 'Usually', value: 'usually' },
          { label: 'Sometimes', value: 'sometimes' },
          { label: 'Never', value: 'never' },
          { label: "I'd rather not say", value: 'no-say' },
        ],
      },
    ],
  },
];

type ResolverShape = Record<string, yup.AnySchema>;
export const getResolver = () => {
  const shape: ResolverShape = {};
  sections.forEach((section) => {
    // TODO -- add this validation check in through yup directly
    // if (section.showIf && !section.showIf(answers)) return;
    section.questions.forEach((question) => {
      shape[question.name] = question.validate;
    });
  });
  const obj = yup.object().shape(shape);
  return yupResolver(obj);
};

export const isComplete = (answers: AnswersData) => {
  return sections.every((section) => {
    if (section.showIf && !section.showIf(answers)) return true;
    return section.questions.every((question) => {
      return answers[question.name];
    });
  });
};
