import { REFERRAL_BANNER_DISMISSED } from '@/utils/constants';
import { useState, useCallback, useEffect } from 'react';
import Mixpanel from '@/utils/mixpanel';
import { BsX } from 'react-icons/bs';
import { Gift } from '@/components/ui/HAIcons';
import Skeleton from 'react-loading-skeleton';
import { useBrandData } from '@/hooks/useBrandData';

type ReferalBannerProps = {
  loading: boolean;
  hostBenefitCredit: number;
};
export function ReferalBanner({
  loading,
  hostBenefitCredit,
}: ReferalBannerProps) {
  const { appName } = useBrandData();
  const [showReferralBanner, setShowReferralBanner] = useState(false);

  const dismissReferralBanner = useCallback((e: any) => {
    e?.preventDefault?.();
    if (typeof localStorage === 'undefined') return;
    localStorage.setItem(REFERRAL_BANNER_DISMISSED, 'true');
    setShowReferralBanner(false);
  }, []);

  useEffect(() => {
    if (typeof localStorage === 'undefined') return;
    const storedReferralBanner = !localStorage.getItem(
      REFERRAL_BANNER_DISMISSED,
    );
    if (storedReferralBanner) {
      Mixpanel.register({
        referral_host_benefit: hostBenefitCredit,
      });
      Mixpanel.track('referral_banner_shown');
      setShowReferralBanner(true);
    }
  }, [hostBenefitCredit]);

  if (!showReferralBanner) return null;

  if (loading) return <Skeleton containerClassName="h-[56px]" />;
  return (
    <a
      className={
        'flex flex-row items-center justify-between sm:mx-1 md:mx-2 lg:mx-0 mb-2 px-4 rounded-lg bg-green'
      }
      href="/refer"
    >
      <div className="flex flex-row items-center my-4">
        <div className="w-5 h-5 flex-shrink-0">
          <Gift color="black" size={20} />
        </div>
        <p className="ml-4">
          Earn ${hostBenefitCredit} when you invite your friends to {appName}
        </p>
      </div>
      <button
        onClick={dismissReferralBanner}
        className="h-11 w-11 flex justify-center items-center"
        aria-label="Close referral banner"
      >
        <BsX className="h-5 w-5" />
      </button>
    </a>
  );
}
