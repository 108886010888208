import { REWARDS_BANNER_DISMISSED } from '@/utils/constants';
import { useState, useCallback, useEffect } from 'react';
import Mixpanel from '@/utils/mixpanel';
import Icon from '@/components/ui/icon';
import colors from '@/utils/colors';
import { BsX } from 'react-icons/bs';
type RewardsBannerProps = {
  loading: boolean;
  cashOutDiscountRate: number;
};

export function RewardsBanner({
  loading,
  cashOutDiscountRate,
}: RewardsBannerProps) {
  const [showRewardsBanner, setShowRewardsBanner] = useState(false);

  const dismissRewardsBanner = useCallback((e: any) => {
    e?.preventDefault?.();
    if (typeof localStorage === 'undefined') return;
    localStorage.setItem(REWARDS_BANNER_DISMISSED, 'true');
    setShowRewardsBanner(false);
  }, []);

  useEffect(() => {
    if (typeof localStorage === 'undefined') return;
    const storedRewardsBanner = !localStorage.getItem(REWARDS_BANNER_DISMISSED);
    if (storedRewardsBanner) {
      Mixpanel.track('rewards_banner_shown');
      setShowRewardsBanner(true);
    }
  }, []);

  if (!showRewardsBanner || loading) return null;
  return (
    <a
      className={
        'flex flex-row items-center justify-between sm:mx-1 md:mx-2 lg:mx-0 mb-2 px-4 rounded-lg bg-green'
      }
      href="/rewards"
    >
      <div className="flex flex-row items-center my-4">
        <div className="w-5 h-5 flex-shrink-0">
          <Icon
            name="dollar-circle-fill"
            size={24}
            color={colors.brand.black}
          />
        </div>
        <div className="ml-4 text-md">
          <p className="font-bold">
            Earn {(cashOutDiscountRate ?? 0.2) * 100}% cash back
          </p>
          <p className="font-normal">
            Get cash back on eligible home-related services
          </p>
        </div>
      </div>
      <button
        onClick={dismissRewardsBanner}
        className="h-11 w-11 flex justify-center items-center"
        aria-label="Close rewards banner"
      >
        <BsX className="h-5 w-5" />
      </button>
    </a>
  );
}
