import { getDurationToNowInHours, getTimezone } from '@/utils/helper';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { gql } from 'src/__generated__';
import {
  CleanerTravelJourneyStatus,
  JobStatus,
} from 'src/__generated__/graphql';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';

const TransitDetailsQuery = gql(/* GraphQL */ `
  query GetTransitDetails($id: ID!) {
    node(id: $id) {
      ... on Job {
        id
        cleanerHasCheckedIn
        cleanerTravelJourney {
          destinationLat
          destinationLng
          eta
          startedAt
          arrivedAt
          status
          mostRecentTravelLog {
            id
            lat
            lng
            time
          }
        }
      }
    }
  }
`);

const timezone = getTimezone();
dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(duration);

type JourneyJobRequirements = {
  id: string;
  start: string;
  status: JobStatus;
};

export function useTrackCleanerTravel({
  start,
  id,
  status,
}: JourneyJobRequirements) {
  const [isFinished, setIsFinished] = useState(false);
  // we use a dynamic poll interval based on the frequency of updates from our cleaners
  const [pollInterval, setPollInterval] = useState(1000 * 30);

  // negative if in the past
  const hoursToNow = getDurationToNowInHours(
    dayjs(start).tz(timezone).toDate(),
  );

  // we are in the window to show the map
  const inWindow = hoursToNow > -1 && hoursToNow < 2;

  // if they are, we want to poll the job to see if the trip has a journey
  // however, we only want to start checking 2 hours before the job start and one hour
  // after the job start (also it has to be claimed)
  const shouldShowMap = !isFinished && status === JobStatus.Claimed && inWindow;

  const { data } = useQuery(TransitDetailsQuery, {
    pollInterval,
    variables: { id },
    skip: !shouldShowMap,
  });

  const job = data?.node?.__typename === 'Job' ? data.node : undefined;

  // only runs on status changes
  useEffect(() => {
    if (isFinished) return;
    const status = job?.cleanerTravelJourney?.status;
    if (!status) return;
    setIsFinished(
      [
        CleanerTravelJourneyStatus.Pending,
        CleanerTravelJourneyStatus.Completed,
        CleanerTravelJourneyStatus.Expired,
        CleanerTravelJourneyStatus.Cancelled,
      ].indexOf(status) > -1,
    );
  }, [job?.cleanerTravelJourney?.status, isFinished]);

  // backoff polling based on cleaner activity
  useEffect(() => {
    const mostRecentLog = job?.cleanerTravelJourney?.mostRecentTravelLog;
    if (!mostRecentLog) return;

    const now = dayjs(new Date());
    const duration = dayjs.duration(
      now.diff(dayjs(mostRecentLog.time).tz(timezone).toDate()),
    );
    const diff = duration.asSeconds();
    if (diff < 60) {
      setPollInterval(1000 * 30); // 30 seconds
    } else if (diff < 60 * 5) {
      setPollInterval(1000 * 60); // one minute
    } else {
      setPollInterval(1000 * 60 * 2); // 2 minutes
    }
  }, [job?.cleanerTravelJourney?.mostRecentTravelLog]);

  const cleanerLocation = useMemo(
    () => job?.cleanerTravelJourney?.mostRecentTravelLog,
    [job?.cleanerTravelJourney],
  );

  const eta: string | undefined = useMemo(
    () => job?.cleanerTravelJourney?.eta,
    [job?.cleanerTravelJourney?.eta],
  );

  const destination = useMemo(() => {
    const lat = job?.cleanerTravelJourney?.destinationLat;
    const lng = job?.cleanerTravelJourney?.destinationLng;
    if (!lat || !lng) return undefined;

    return { lat, lng };
  }, [
    job?.cleanerTravelJourney?.destinationLat,
    job?.cleanerTravelJourney?.destinationLng,
  ]);

  return {
    destination:
      !job?.cleanerTravelJourney?.arrivedAt && shouldShowMap
        ? destination
        : undefined,
    journey: job?.cleanerTravelJourney,
    eta,
    cleanerLocation,
  };
}
