import { useCallback, useEffect, useMemo, useState } from 'react';
import { CreditCardModal } from '@/components/features/CreditCardModal';
import { useRouter } from 'next/router';
import { makeLogger } from '@/utils/makeLogger';
import NoUpcomingTile from '@/components/ui/NoUpcomingTile';
import { useQuery } from '@apollo/client';
import {
  JobListDetailsFragment,
  JobSortDirection,
  JobStatus,
  JobsListQuery,
  JobsListQueryVariables,
} from 'src/__generated__/graphql';
import { startOfDay } from '@/utils/helper';
import AppointmentList from '@/components/features/AppointmentList';
import Button from '@/components/ui/Button';
import { gql } from 'src/__generated__';
import { throwIfUnauthenticatedCustomer } from '@/utils/narrow-utils';
import GraphQLAlert, { AlertProps } from '@/components/ui/Alert';
import { isComplete } from '@/components/features/ProfileAttributes/data';
import Icon from '@/components/ui/icon';
import Modal from '@/components/ui/Modal';
import isDefined from '@/utils/isDefined';
import { WaitlistAdBanner } from '@/components/features/Banners/WaitlistAdBanner';
import { RewardsBanner } from '@/components/features/Banners/RewardsBanner';
import { ReferalBanner } from '@/components/features/Banners/ReferalBanner';

const log = makeLogger('pages/index');

const CUSTOMER_DATA = gql(/* GraphQL */ `
  query CustomerDashboard($keys: [String]!) {
    me {
      ... on Customer {
        id
        numInvoicedJobs
        tmpProfileAttributesJson
        cashBackProgram {
          cashOutDiscountRate
        }
        savedPaymentMethod {
          id
          walletType
          last4
        }
        vouchers {
          id
          merchant
        }
        customerReferralTerms {
          benefitsReceivedAt
          hostBenefitCredit
          guestBenefitCredit
        }
        legacyAlerts {
          ... on FirstJobAlert {
            start
          }
          ... on SystemCancelJobAlert {
            starts
          }
          ... on FailedChargesAlert {
            message
          }
          ... on CreditsAvailableAlert {
            creditsAvailable
          }
        }
        experiments(keys: $keys) {
          key
          value
        }
      }
    }
  }
`);

const JOBS_LIST = gql(/* GraphQL */ `
  query JobsList($filter: BasicJobFilter, $after: String, $first: Int) {
    me {
      ... on Customer {
        id
        jobs(filter: $filter, after: $after, first: $first) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              ...JobListDetails
            }
          }
        }
      }
    }
  }

  fragment JobListDetails on Job {
    id
    rawId
    cleaner {
      id
      rawId
      avgStars
      firstName
      lastName
      photo
      numReviews
    }
    reviews {
      id
      stars
    }
    advancedReview {
      id
    }
    recurringContract {
      id
      frequency
    }
    location {
      id
      street
      apt
      lat
      lng
      cleaningNotes
    }
    extraServices
    numHours
    start
    preferredStartWindows {
      startOfWindow
      endOfWindow
      priorityMarkupPct
    }
    customerTip {
      amount
    }
    invoicedDate
    status
    legacyAlerts {
      ... on CPRescheduledNotRequestedAlert {
        __typename
        date
      }
      ... on CPSwappedAlert {
        cpFirstName
      }
      ... on HoursChangedCPNotApprovedAlert {
        lastApprovedNumHours
      }
      ... on ChargedForLastMinuteCancelAlert {
        date
      }
      ... on ChargedForLockoutAlert {
        date
      }
    }
  }
`);

const futureJobsVariables: JobsListQueryVariables = {
  filter: {
    jobStatus: ['claimed', 'submitted', 'pending_invoice', 'invoiced'],
    startGte: startOfDay()?.toISOString() || new Date().toISOString(),
    sortDir: JobSortDirection.Asc,
  },
  first: 2,
};

const pastJobsVariables: JobsListQueryVariables = {
  filter: {
    jobStatus: ['claimed', 'submitted', 'pending_invoice', 'invoiced'],
    startLte: startOfDay()?.toISOString() || new Date().toISOString(),
    sortDir: JobSortDirection.Desc,
  },
  first: 2,
};

const cancelledJobsVariables: JobsListQueryVariables = {
  filter: {
    jobStatus: ['cancelled'],
    sortDir: JobSortDirection.Desc,
  },
  first: 2,
};

// narrows a data response from jobs list to the actual jobs list nodes
const jobListLens = (data?: JobsListQuery) => {
  if (data?.me?.__typename !== 'Customer') return [];
  return (
    (data?.me?.jobs?.edges
      ?.map((edge) => edge?.node)
      ?.filter(Boolean) as Array<JobListDetailsFragment>) || []
  );
};

export default function Dashboard() {
  const router = useRouter();
  const [showCancelled, setShowCancelled] = useState(false);

  /**
   * Future
   */
  const {
    data: futureJobsData,
    fetchMore: _fetchMoreFutureJobs,
    loading: futureJobsLoading,
  } = useQuery(JOBS_LIST, {
    variables: futureJobsVariables,
    fetchPolicy: 'cache-and-network',
  });
  const jobList = useMemo(() => jobListLens(futureJobsData), [futureJobsData]);
  const fetchMoreFutureJobs = useCallback(() => {
    _fetchMoreFutureJobs({
      variables: {
        after:
          futureJobsData?.me?.__typename === 'Customer'
            ? futureJobsData.me.jobs?.pageInfo?.endCursor
            : null,
      },
    });
  }, [_fetchMoreFutureJobs, futureJobsData]);

  /**
   * Past
   */
  const {
    data: pastJobsData,
    fetchMore: _fetchMorePastJobs,
    loading: pastjobsLoading,
  } = useQuery<JobsListQuery, JobsListQueryVariables>(JOBS_LIST, {
    variables: pastJobsVariables,
  });
  const pastJobList = useMemo(() => jobListLens(pastJobsData), [pastJobsData]);
  const fetchMorePastJobs = useCallback(() => {
    _fetchMorePastJobs({
      variables: {
        after:
          pastJobsData?.me?.__typename === 'Customer'
            ? pastJobsData.me.jobs?.pageInfo?.endCursor
            : null,
      },
    });
  }, [_fetchMorePastJobs, pastJobsData]);

  /**
   * Cancelled
   */
  const {
    data: cancelledJobsData,
    fetchMore: _fetchMoreCancelledJobs,
    loading: cancelledjobsLoading,
  } = useQuery<JobsListQuery, JobsListQueryVariables>(JOBS_LIST, {
    variables: cancelledJobsVariables,
  });
  const cancelledJobList = useMemo(
    () => jobListLens(cancelledJobsData),
    [cancelledJobsData],
  );
  const fetchMoreCancelledJobs = useCallback(() => {
    _fetchMoreCancelledJobs({
      variables: {
        after:
          cancelledJobsData?.me?.__typename === 'Customer'
            ? cancelledJobsData.me.jobs?.pageInfo?.endCursor
            : null,
      },
    });
  }, [_fetchMoreCancelledJobs, cancelledJobsData]);

  const { data, loading: customerDataLoading } = useQuery(CUSTOMER_DATA, {
    variables: { keys: ['customer_services_waitlist_enabled'] },
  });
  const customerData = throwIfUnauthenticatedCustomer(data);
  const experiments = useMemo(() => {
    if (!customerData?.me?.experiments) {
      return Object.create(null) as Record<string, string | undefined | null>;
    }
    return Object.fromEntries(
      customerData.me.experiments
        .filter(isDefined)
        .map(({ key, value }) => [key, value]),
    );
  }, [customerData?.me?.experiments]);

  // No saved card (and not loading) and has upcoming jobs
  const showAddCardAlert = useMemo(() => {
    if (customerDataLoading) {
      log('[showAddCardAlert]', false, 'loading');
      return false;
    }

    const hasUpcoming = !!jobList.find((j) => {
      return j.status === JobStatus.Submitted || j.status === JobStatus.Claimed;
    });

    if (
      hasUpcoming &&
      customerData?.me?.savedPaymentMethod?.walletType === 'apple_pay'
    ) {
      log('[showAddCardAlert]', true, 'apple pay');
      return true;
    }

    if (!customerData?.me?.savedPaymentMethod?.last4 && hasUpcoming) {
      log('[showAddCardAlert]', true, 'no card, upcoming job');
      return true;
    } else {
      log('[showAddCardAlert]', false, 'saved card || no upcoming');
      return false;
    }
  }, [
    customerData?.me?.savedPaymentMethod?.last4,
    customerData?.me?.savedPaymentMethod?.walletType,
    jobList,
    customerDataLoading,
  ]);

  const showProfileAlert = useMemo(() => {
    if (customerDataLoading) {
      return false;
    }
    if (typeof localStorage === 'undefined') return false;
    if (!customerData?.me?.tmpProfileAttributesJson) {
      return true;
    }
    try {
      const profileData = JSON.parse(
        customerData?.me?.tmpProfileAttributesJson,
      );
      if (!isComplete(profileData)) return true;
    } catch (e) {
      return true;
    }
    return false;
  }, [customerDataLoading, customerData?.me?.tmpProfileAttributesJson]);

  const hasVoucher =
    data?.me?.__typename === 'Customer' &&
    (data?.me?.vouchers?.length || 0) > 0 &&
    data?.me?.vouchers?.some((voucher) => voucher?.merchant === 'dhj');

  const showAddCardModal = useMemo(() => {
    // by waiting until this is loaded, whenever we render the
    // modal, we can verify which case the user is in to change the modal,
    // 1. apple pay - booking is confirmed,
    // 2. paypal - booking is NOT confirmed,

    // loading -> don't show modal
    // has a card NOT apple pay -> don't show modal
    if (
      customerDataLoading ||
      (customerData?.me?.savedPaymentMethod?.last4 &&
        customerData?.me?.savedPaymentMethod?.walletType !== 'apple_pay')
    ) {
      log('[showAddCardModal]', false, {
        customerDataLoading,
        savedPayment: customerData?.me?.savedPaymentMethod,
      });
      return false;
    }

    // if user can book without a cc, then immediately show the add card modal once if
    // ?add_card=true is in the url
    if (router?.query?.add_card === 'true') {
      log('[showAddCardModal]', true, 'query string');
      return true;
    }

    // user landing on this page from another page, no query param
    // has card -- returns earlier, don't show.
    // no card --
    //   claimed job -- show modal
    if (
      jobList.find((j) => {
        return j.status === JobStatus.Claimed;
      })
    ) {
      log('[showAddCardModal]', true, 'claimed job');
      return true;
    }

    log('[showAddCardModal]', false, 'default');
    return false;
  }, [
    customerData?.me?.savedPaymentMethod,
    jobList,
    router?.query?.add_card,
    customerDataLoading,
  ]);

  // Booking confirmed modal
  const [isOpen, setIsOpen] = useState(false);
  const [fcRestarted, setFCRestarted] = useState(false);
  const [apptHasPriorityMarkup, setApptHasPriorityMarkup] = useState(false);
  useEffect(() => {
    if (router?.query?.booked === 'true') {
      log('[showBookingConfirmedModal]', true);
      if (router.query.fcr === 'true') {
        setFCRestarted(true);
      }
      if (router.query.pm === 'true') {
        setApptHasPriorityMarkup(true);
      }
      setIsOpen(true);
      router.push('/', undefined, { shallow: true });
    }
  }, [router]);

  const newAlerts = useMemo(() => {
    const alerts: Array<AlertProps> = [];
    if (showAddCardAlert) {
      alerts.push({
        data: { __typename: 'NoCardAlert' },
      });
    }

    if (showProfileAlert) {
      alerts.push({
        data: { __typename: 'ProfileAttributesAlert' },
      });
    }

    // iterate over the 3 jobs lists and get the alers from their legacyAlerts
    // and add to the list of alerts
    [...jobList, ...pastJobList, ...cancelledJobList].forEach((job) => {
      if (job.legacyAlerts) {
        job.legacyAlerts.forEach((alert) => {
          if (alert)
            alerts.push({
              data: alert,
              jobId: job.rawId,
              cleanerFirstName: job.cleaner?.firstName,
            });
        });
      }
    });

    // add customer alerts
    if (customerData?.me?.legacyAlerts) {
      customerData.me.legacyAlerts.forEach((alert) => {
        if (alert) alerts.push({ data: alert });
      });
    }
    return alerts;
  }, [
    showAddCardAlert,
    showProfileAlert,
    jobList,
    pastJobList,
    cancelledJobList,
    customerData?.me?.legacyAlerts,
  ]);

  return (
    <>
      {isOpen ? (
        <Modal
          title={
            apptHasPriorityMarkup
              ? 'Your priority request has been received'
              : `Thanks for booking!`
          }
          isOpen={isOpen}
          close={() => setIsOpen(false)}
        >
          <div className="max-w-[400px]">
            <p>
              We&apos;ll send you the exact time and date once you&apos;ve been
              matched with a cleaner.
            </p>
            {/* FC was restarted on booking */}
            {fcRestarted && (
              <div className="p-4 flex flex-row items-center justify-center bg-lightestPurple rounded-lg mt-4">
                <Icon
                  name="seal-check"
                  size={32}
                  className="fill-purple mr-3"
                />
                <div>
                  <p className="font-bold">Welcome back!</p>
                  <p>
                    You can now enjoy all the benefits of our membership plan.
                  </p>
                </div>
              </div>
            )}
            <Button className="mt-6 w-full" onClick={() => setIsOpen(false)}>
              Okay, got it!
            </Button>
          </div>
        </Modal>
      ) : null}
      {showAddCardModal ? (
        // apple pay users with saved cc will see this modal, but we will say their booking
        // IS confirmed, but paypal users will say NOT confirmed, since they have NO card.
        <CreditCardModal
          bookingConfirmed={!!customerData?.me?.savedPaymentMethod?.last4}
        />
      ) : null}
      {!!customerData?.me?.customerReferralTerms?.hostBenefitCredit &&
        !!customerData?.me?.numInvoicedJobs && (
          <ReferalBanner
            loading={customerDataLoading}
            hostBenefitCredit={
              customerData.me.customerReferralTerms.hostBenefitCredit
            }
          />
        )}
      {!!customerData?.me?.numInvoicedJobs &&
        !!customerData.me.cashBackProgram && (
          <RewardsBanner
            loading={customerDataLoading}
            cashOutDiscountRate={
              customerData.me.cashBackProgram.cashOutDiscountRate
            }
          />
        )}
      {!!customerData?.me?.numInvoicedJobs && (
        <WaitlistAdBanner
          experiments={experiments}
          loading={customerDataLoading}
        />
      )}
      {newAlerts.length > 0 ? (
        <div className="mb-4 sm:mx-1 md:mx-2 lg:mx-0">
          {newAlerts.map((alert, idx) => {
            return <GraphQLAlert key={idx} {...alert} />;
          })}
        </div>
      ) : null}

      {!futureJobsLoading && jobList.length === 0 ? (
        <NoUpcomingTile hasVoucher={hasVoucher} />
      ) : (
        <AppointmentList
          jobs={jobList}
          loading={futureJobsLoading}
          fetchMore={fetchMoreFutureJobs}
          hasMore={
            futureJobsData?.me?.__typename === 'Customer' &&
            !!futureJobsData?.me.jobs?.pageInfo?.hasNextPage
          }
          title={'Upcoming appointments'}
          emptyListMessage={'You have no upcoming appointments'}
        />
      )}

      {pastJobList.length ? (
        <>
          <div className="h-4" />
          <AppointmentList
            jobs={pastJobList}
            loading={pastjobsLoading}
            fetchMore={fetchMorePastJobs}
            hasMore={
              pastJobsData?.me?.__typename === 'Customer' &&
              !!pastJobsData?.me.jobs?.pageInfo?.hasNextPage
            }
            title={'Past appointments'}
            emptyListMessage={'You have no past appointments'}
          />
        </>
      ) : null}

      {cancelledJobList.length > 0 ? (
        <>
          <div className="h-4" />

          {showCancelled ? (
            <AppointmentList
              jobs={cancelledJobList}
              loading={cancelledjobsLoading}
              fetchMore={fetchMoreCancelledJobs}
              hasMore={
                cancelledJobsData?.me?.__typename === 'Customer' &&
                !!cancelledJobsData?.me.jobs?.pageInfo?.hasNextPage
              }
              title={'Cancelled appointments'}
              emptyListMessage={'You have no cancelled appointments'}
            />
          ) : (
            <div className="sm:mx-2 md:mx-4 lg:mx-0">
              <Button
                className="w-full"
                size="sm"
                variant="secondary"
                onClick={() => setShowCancelled(true)}
              >
                Show cancelled appointments
              </Button>
            </div>
          )}
        </>
      ) : null}
    </>
  );
}
