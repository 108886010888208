import clsx from 'clsx';
import { services } from './services';
import Icon from '@/components/ui/icon';
import colors from '@/utils/colors';
import { FragmentType, gql, useFragment as fragment } from 'src/__generated__';
import { OfferedServices } from 'src/__generated__/graphql';

const ServiceListFragment = gql(/* GraphQL */ `
  fragment WaitListedServices on Customer {
    waitListServices {
      isOnWaitList
      service
    }
  }
`);

type ServiceListProps = {
  customer: FragmentType<typeof ServiceListFragment>;
  onServiceSelect: (_service: OfferedServices) => void;
};

export function ServiceList(props: ServiceListProps) {
  const customer = fragment(ServiceListFragment, props.customer);
  return (
    <div className=" w-full overflow-scroll relative bg-white pl-4 pr-4 py-6 lg:pt-6 pt-12 lg:pl-4 lg:pr-0">
      <p className="text-xl font-bold mb-6 pr-8">
        What service do you want to book with us today?
      </p>
      <div className="flex flex-row flex-wrap items-start w-full">
        {customer.waitListServices.map((x, index) => {
          const service = services[x.service];
          const active = x.isOnWaitList;
          return (
            <button
              onClick={() => props.onServiceSelect(x.service)}
              disabled={active}
              key={service.name}
              className={clsx(
                'flex flex-row justify-start items-start w-full lg:w-auto lg:min-w-[292px] lg:mb-4 border border-lightGray rounded-lg py-3 px-4',
                index < customer.waitListServices.length - 1 && 'mb-3',
                index % 2 === 1 ? 'l' : 'lg:mr-4',
                active ? 'bg-lightGray' : 'bg-lightestGray',
              )}
            >
              <div className="p-[6px] bg-white border border-lightGray flex-shrink-0 rounded-lg overflow-clip self-center mr-4">
                <Icon
                  name={service.icon}
                  size={48}
                  color={colors.brand.purple}
                />
              </div>
              <div className="self-center flex flex-col">
                <p
                  className={clsx(
                    'text-md font-medium text-left',
                    active && 'text-darkGray',
                  )}
                >
                  {service.name}
                </p>
                {active && (
                  <div className="flex-row items-start flex">
                    <Icon
                      name="check"
                      size={20}
                      className="mr-1"
                      color={colors.brand.gray.dark}
                    />
                    <p className="text-darkGray text-sm leading-[20px]">
                      You&#39;re on the waitlist
                    </p>
                  </div>
                )}
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
}
