import React, { useMemo } from 'react';
import Image from 'next/image';
import {
  formatETAString,
  formatPreferredStartWindow,
  formatTime,
  getArrivalTimeRangeString,
  humanizeDate,
} from '@/utils/helper';
// @ts-ignore
import ApptHero from '../../../public/appt-hero.jpg';
import { BsChevronRight, BsPerson, BsStarFill } from 'react-icons/bs';
import colors from '@/utils/colors';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import {
  Frequency,
  JobListDetailsFragment,
  JobStatus,
} from 'src/__generated__/graphql';
import Button from './Button';
import * as Sentry from '@sentry/nextjs';
import Link from 'next/link';
import { useTrackCleanerTravel } from '@/components/features/CleanerTravelJourney/useTrackCleanerTravel';
import { TravelMap } from '@/components/features/CleanerTravelJourney/TravelMap';
import Badge from './Badge';
import UsernameAvatar from './UsernameAvatar';
import { jobIsReviewableOrTippable } from '@/utils/jobIsReviewableOrTippable';
import isDefined from '@/utils/isDefined';

dayjs.extend(utc);
dayjs.extend(tz);

const APPOINTMENT_FREQUENCY_STRING: Record<string, string> = {
  [Frequency.Weekly]: 'Every Week',
  [Frequency.Every_2Weeks]: 'Every 2 Weeks',
  [Frequency.Every_4Weeks]: 'Every 4 Weeks',
};

const _AppointmentTile = ({ job }: { job: JobListDetailsFragment }) => {
  const router = useRouter();
  const { start, preferredStartWindows, cleaner, recurringContract, location } =
    job;

  const formattedStartWindows = useMemo(() => {
    if (!preferredStartWindows?.length) return;
    return preferredStartWindows
      ?.map((window) => {
        if (!window?.startOfWindow) return;
        return formatPreferredStartWindow(window);
      })
      .filter(Boolean);
  }, [preferredStartWindows]);

  const { destination, eta, cleanerLocation } = useTrackCleanerTravel({
    start: job.start,
    id: job.id,
    status: job.status,
  });

  const date = start ? new Date(start) : undefined;
  const dateString = date ? humanizeDate(date) : undefined;
  const arrivalString = eta
    ? formatETAString(eta)
    : date
    ? getArrivalTimeRangeString(date)
    : '';
  const startString = date ? formatTime(date) : undefined;
  const reviewed = job?.reviews?.length || job?.advancedReview;

  // TODO -- i need to fix the types of this at some point, but i'm not sure how right now
  const [isReviewableOrTippable, { hasReview, hasTip }] =
    jobIsReviewableOrTippable(job as any);

  const formatStars = (num?: number | null) =>
    num ? (Number.isInteger(num) ? `${num}.0` : num.toFixed(1)) : '5.0';

  const cpName = `${[cleaner?.firstName, cleaner?.lastName?.toUpperCase()].join(
    ' ',
  )}.`;

  const frequencyString = useMemo(() => {
    const frequency = recurringContract?.frequency?.toLowerCase();
    return frequency ? APPOINTMENT_FREQUENCY_STRING[frequency] : undefined;
  }, [recurringContract]);

  const showPriorityNotice = useMemo(() => {
    const hasPriorityMarkup = job.preferredStartWindows
      ?.filter(isDefined)
      ?.some((w) => w.priorityMarkupPct);

    // Show priority notice if there is a priority markup AND the job has not been cancelled or invoiced
    return (
      hasPriorityMarkup &&
      ![JobStatus.Cancelled, JobStatus.Invoiced].includes(job.status)
    );
  }, [job.preferredStartWindows, job.status]);

  // Within the 24-hour cancel window
  const isSoon = useMemo(() => {
    const msUntilStart = job?.start
      ? new Date(job?.start).getTime() - Date.now()
      : undefined;
    const moreThan1DaysOut = msUntilStart
      ? msUntilStart > 24 * 60 * 60 * 1000
      : undefined;
    return !moreThan1DaysOut;
  }, [job?.start]);

  const link = `/appointment/${job.rawId}`;

  return (
    <div
      className="flex flex-col w-full overflow-hidden border border-lightGray rounded-lg focus-within:shadow-md hover:shadow-md relative cursor-pointer bg-white"
      onClick={() => {
        router.push(link);
      }}
    >
      {destination ? (
        <div className="sm:h-[200px] lg:h-[300px] w-full bg-lightGray">
          <TravelMap
            destination={destination}
            cleanerLocation={cleanerLocation}
          />
          <div className="absolute inset-0"></div>
        </div>
      ) : (
        <div className="w-full bg-gray">
          <Image
            alt="Clean home"
            src={ApptHero}
            style={{ opacity: job.status === JobStatus.Cancelled ? 0.5 : 1 }}
            priority
            placeholder="blur"
            className="h-full sm:max-h-[200px] md:max-h-[250px] lg:max-h-[300px] object-cover object-bottom w-full"
          />
        </div>
      )}
      <div className="flex flex-col absolute top-4 right-4 items-center">
        {job.status === JobStatus.Submitted ? (
          <div className="flex bg-yellow rounded-lg overflow-hidden items-center">
            <p className="mx-2 font-semibold">Finding a cleaner</p>
            <div className="flex justify-center items-center h-12 w-12 bg-lightestGray rounded-lg overflow-hidden">
              <BsPerson size={'24px'} color={colors.brand.gray.dark} />
            </div>
          </div>
        ) : null}

        {job.status === JobStatus.Cancelled ? (
          <Badge className="text-white bg-darkerRed">Cancelled</Badge>
        ) : null}

        {job.status &&
        [
          JobStatus.Claimed,
          JobStatus.Invoiced,
          JobStatus.PendingInvoice,
        ].includes(job.status) &&
        job.cleaner ? (
          <div className="flex overflow-hidden bg-green rounded-lg">
            <div className="flex flex-col items-center justify-center h-16 px-2">
              <p className="font-bold">{cpName}</p>
              <div className="flex flex-row items-center">
                <p className="mr-2 leading-none">
                  {formatStars(job.cleaner.avgStars)}
                </p>
                <BsStarFill size={12} />
              </div>
            </div>
            {job.cleaner.photo ? (
              <UsernameAvatar
                size={64}
                photo={job.cleaner.photo || undefined}
                firstName={cleaner?.firstName}
                lastName={cleaner?.lastName}
              />
            ) : (
              <BsPerson size={'48px'} color={colors.brand.gray.dark} />
            )}
          </div>
        ) : null}
      </div>

      {/* Bottom Section */}
      <div className="flex flex-col flex-1">
        {/* Appt info box */}
        <div className="flex relative items-center px-4 py-4">
          <div className="flex flex-col flex-1">
            <div className="flex">
              <Link
                className="font-bold"
                href={link}
                id={job.rawId + '-title'}
                aria-labelledby={
                  job.rawId + '-title' + ' ' + job.rawId + '-arrival'
                }
              >
                Home Cleaning for {job.numHours} hours
              </Link>
              {frequencyString && job.status === JobStatus.Submitted ? (
                <Badge className="ml-2 bg-lightGray flex items-center">
                  {frequencyString}
                </Badge>
              ) : null}
            </div>

            {/* Submitted/pending - show windows */}
            {job?.status === JobStatus.Submitted ||
            job?.status === JobStatus.Pending ? (
              formattedStartWindows?.length ? (
                <p className="mt-2 font-bold" id={job.rawId + '-arrival'}>
                  {formattedStartWindows[0]?.date}
                  <span className="font-normal">
                    {`${
                      formattedStartWindows.length > 1
                        ? ` or ${formattedStartWindows.length - 1} other day${
                            formattedStartWindows.length > 2 ? 's' : ''
                          }`
                        : ''
                    }`}
                  </span>
                </p>
              ) : null
            ) : null}

            {/* Claimed/pending invoice - Show arrival string */}
            {job?.status === JobStatus.PendingInvoice ||
            job?.status === JobStatus.Claimed ? (
              dateString ? (
                <>
                  <p className="mt-2" id={job.rawId + '-arrival'}>
                    <strong>{dateString}</strong>
                  </p>
                  <p>Expected arrival {arrivalString}</p>
                </>
              ) : null
            ) : null}

            {/* Cancelled/Invoiced - show start time */}
            {job?.status === JobStatus.Cancelled ||
            job?.status === JobStatus.Invoiced ? (
              dateString ? (
                <>
                  <p className="mt-2" id={job.rawId + '-arrival'}>
                    <strong>{dateString}</strong>
                    {startString ? ' - ' + startString : null}
                  </p>
                </>
              ) : null
            ) : null}

            {location ? (
              <p>
                {location.street}
                {location.apt ? `, ${location.apt}` : ''}
              </p>
            ) : null}

            {reviewed ? (
              <Badge className="mt-1 font-semibold bg-green text-black self-start">
                Thank you for your review {hasTip ? 'and tip' : ''}
              </Badge>
            ) : hasTip ? (
              <Badge className="mt-1 font-semibold bg-green text-black self-start">
                Thank you for tipping
              </Badge>
            ) : null}

            {(job.status === JobStatus.Submitted ||
              job.status === JobStatus.Claimed) &&
            !job.location?.cleaningNotes ? (
              <Badge className="mt-1 font-semibold bg-yellow self-start">
                No cleaning notes added
              </Badge>
            ) : null}
          </div>
          {!reviewed && job.status === JobStatus.Invoiced ? null : (
            <BsChevronRight
              size={24}
              fill={colors.brand.gray.dark}
              color={colors.brand.gray.dark}
              // Since the link surrounding this has proper text, and this isn't a button
              // by itself, so we don't need a label here
              aria-hidden
            />
          )}
        </div>

        {/* Review/view details buttons */}
        {isReviewableOrTippable ? (
          <div className="mb-4 mx-4 flex">
            <Button
              size="sm"
              onClick={(e) => {
                router.push(`/appointment/${job.rawId}/review`);
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
              }}
              className="flex-1"
            >
              {!hasReview && !hasTip
                ? 'Review or tip'
                : hasReview
                ? 'Leave a tip'
                : 'Leave a review'}
            </Button>
            <Button
              onClick={(e) => {
                router.push(`/appointment/${job.rawId}`);
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
              }}
              variant="secondary"
              size="sm"
              className="ml-2 flex-1"
            >
              See details
            </Button>
          </div>
        ) : null}

        {showPriorityNotice && (
          <div className="p-4 border-t border-t-lightGray bg-white">
            <p className="text-xs">
              <span className="font-semibold">Priority request</span>. Your
              chosen time is a high-demand time for cleaners. Priority rates{' '}
              {job.cleaner ? 'will' : 'may'} apply.{' '}
              {job.cleaner || isSoon ? '' : 'Reschedule for discounted rates.'}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

const AppointmentTile = (props: { job: JobListDetailsFragment }) => {
  return (
    <Sentry.ErrorBoundary fallback={<div>There was an error</div>}>
      <_AppointmentTile {...props} />
    </Sentry.ErrorBoundary>
  );
};
export default React.memo(AppointmentTile);
