import { IconName } from '@/components/ui/icon';
import { OfferedServices } from 'src/__generated__/graphql';

type ServiceConfig = {
  name: string;
  icon: IconName;
};

export const services: Record<OfferedServices, ServiceConfig> = {
  [OfferedServices.CarWash]: {
    name: 'Car wash (exterior)',
    icon: 'car-wash-active',
  },
  [OfferedServices.CourierService]: {
    name: 'Courier service',
    icon: 'courier-active',
  },
  [OfferedServices.DogWalking]: {
    name: 'Dog walking',
    icon: 'dog-active',
  },
  [OfferedServices.DryCleaning]: {
    name: 'Dry cleaning',
    icon: 'dry-cleaning-active',
  },
  [OfferedServices.FurnitureAssembly]: {
    name: 'Furniture assembly',
    icon: 'furniture-assembly-active',
  },
  [OfferedServices.HomeOrganizing]: {
    name: 'Home organizing',
    icon: 'organizing-active',
  },
  [OfferedServices.InteriorCarCleaning]: {
    name: 'Car cleaning (interior)',
    icon: 'car-detailing-active',
  },
  [OfferedServices.InteriorPainting]: {
    name: 'Interior painting',
    icon: 'paintbrush-active',
  },
  [OfferedServices.JunkRemoval]: {
    name: 'Junk removal',
    icon: 'junk-removal-active',
  },
  [OfferedServices.LawnMowing]: {
    name: 'Lawn mowing',
    icon: 'mowing-active',
  },
  [OfferedServices.MealPrep]: {
    name: 'Meal prep',
    icon: 'meal-prep-active',
  },
  [OfferedServices.MothersHelper]: {
    name: "Mother's helper",
    icon: 'stroller-active',
  },
  [OfferedServices.PetBoarding]: {
    name: 'Pet boarding',
    icon: 'pet-sitting-active',
  },
  [OfferedServices.RunningErrands]: {
    name: 'Running errands',
    icon: 'errands-active',
  },
  [OfferedServices.Weeding]: {
    name: 'Weeding',
    icon: 'weeding-active',
  },
};
